import { JSON_CONTENT_TYPE } from '../constant/constants';
import { SignInDto } from '../dto/SignInDto';
import { IApiParams } from '../interface/IApiParams';
import { getApi } from './getApi';

export const apiSignIn = async (dto: SignInDto) => {
  const params: IApiParams = {
    url: '/signIn.php',
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
    },
  };
  return getApi(params, 'POST', dto);
};
