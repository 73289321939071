import React, { useEffect, useState } from 'react';
import {
  Box,
  createTheme,
  ThemeProvider,
  Stack,
  Typography,
  Divider,
} from '@mui/material';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';

import { getDefaultAlertProps } from '../../utils/utils';
import { apiGetTankToWeb } from '../../services/apiGetTankToWeb';
import { IApiResult } from '../../interface/IApiResult';
import { IAlertProps } from '../../interface/IAlertProps';
import { getParamColorStyle, paramStringRound } from '../../utils/common';

import { API_STATUS_PROBLEM } from '../../constant/constants';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';

import { GetTankToWebRespDto } from '../../dto/GetTankToWebRespDto';

export const MainDivMobile = () => {
  const [tankToWebRespDto, setTankToWebRespDto] = useState<
    GetTankToWebRespDto[]
  >([]);

  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );

  const getTankInfo = async () => {
    const apiResult: IApiResult = await apiGetTankToWeb();
    if (apiResult.isSuccess) {
      setTankToWebRespDto(apiResult.data);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  useEffect(() => {
    getTankInfo();
  }, []);

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    ruRU
  );

  const other = {
    autoHeight: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
  };

  const columns: GridColDef[] = [
    {
      field: 'Level',
      flex: 1,
      headerName: 'Параметры',
      editable: false,
      disableColumnMenu: false,
      renderCell: (row) => (
        <Stack spacing={1} width="100%">
          {row.row.IsWork === 0 && (
            <Stack direction="row" spacing={1}>
              <Typography
                style={{
                  fontFamily: 'SF Compact Rounded',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '12px',
                  color: 'red',
                }}
                component="text"
              >
                Сервер не отвечает. Проверьте работу сервера!
              </Typography>
            </Stack>
          )}
          <Stack direction="row" spacing={1}>
            <Typography
              style={{
                fontFamily: 'SF Compact Rounded',
                fontStyle: 'normal',
                fontWeight: 600,
                color: '#000000',
              }}
              component="text"
            >
              {`${row.row.TankType}-${row.row.TankCapacity}`}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Typography
              style={{
                fontFamily: 'SF Compact Rounded',
                fontStyle: 'normal',
                fontWeight: 600,
                color: '#000000',
              }}
              component="text"
            >
              {row.row.TankName}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Typography
              style={{
                fontFamily: 'SF Compact Rounded',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '12px',
                color: '#000000',
              }}
              component="text"
            >
              {row.row.DatetimeCicl}
            </Typography>
          </Stack>
          <Stack direction="row" width="100%" justifyContent="space-between">
            <Typography
              style={{
                fontFamily: 'SF Compact Rounded',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '12px',
                color: '#000000',
              }}
              component="text"
            >
              Уровень, м
            </Typography>
            <Typography
              style={{
                fontFamily: 'SF Compact Rounded',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '12px',
                color: getParamColorStyle(row.row.LevelStatus, true),
              }}
              component="text"
            >
              {paramStringRound(row.row.Level, 3)}
            </Typography>
          </Stack>
          <Stack direction="row" width="100%" justifyContent="space-between">
            <Typography
              style={{
                fontFamily: 'SF Compact Rounded',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '12px',
                color: '#000000',
              }}
              component="text"
            >
              Температура, °C
            </Typography>
            <Typography
              style={{
                fontFamily: 'SF Compact Rounded',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '12px',
                color: getParamColorStyle(row.row.TempStatus, true),
              }}
              component="text"
            >
              {paramStringRound(row.row.TempAver, 2)}
            </Typography>
          </Stack>
          <Stack direction="row" width="100%" justifyContent="space-between">
            <Typography
              style={{
                fontFamily: 'SF Compact Rounded',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '12px',
                color: '#000000',
              }}
              component="text"
            >
              Плотность, кг/м³
            </Typography>
            <Typography
              style={{
                fontFamily: 'SF Compact Rounded',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '12px',
                color: getParamColorStyle(row.row.DensStatus, true),
              }}
              component="text"
            >
              {paramStringRound(row.row.Dens, 2)}
            </Typography>
          </Stack>
          <Stack direction="row" width="100%" justifyContent="space-between">
            <Typography
              style={{
                fontFamily: 'SF Compact Rounded',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '12px',
                color: '#000000',
              }}
              component="text"
            >
              Объем, м³
            </Typography>
            <Typography
              style={{
                fontFamily: 'SF Compact Rounded',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '12px',
                color: getParamColorStyle(row.row.VolumeStatus, true),
              }}
              component="text"
            >
              {paramStringRound(row.row.Volume, 3)}
            </Typography>
          </Stack>
          <Stack direction="row" width="100%" justifyContent="space-between">
            <Typography
              style={{
                fontFamily: 'SF Compact Rounded',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '12px',
                color: '#000000',
              }}
              component="text"
            >
              Масса, т
            </Typography>
            <Typography
              style={{
                fontFamily: 'SF Compact Rounded',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '12px',
                color: getParamColorStyle(row.row.MassStatus, true),
              }}
              component="text"
            >
              {paramStringRound(row.row.Mass, 3)}
            </Typography>
          </Stack>
        </Stack>
      ),
    },
  ];

  return (
    <Stack padding="5px">
      <div>
        <Box
          sx={{
            width: '100%',
            '& .stage-black': {
              color: '#000000',
              fontWeight: 100,
              fontSize: 12,
            },
            '& .stage-orange': {
              color: '#orange',
              fontWeight: 100,
              fontSize: 12,
            },
            '& .stage-red': {
              color: '#ff0000',
              fontWeight: 100,
              fontSize: 12,
            },

            '& .stage-magenta': {
              color: '#ff00ff',
              fontWeight: 100,
              fontSize: 12,
            },

            '& .stage-blue': {
              color: '#0000ff',
              fontWeight: 100,
              fontSize: 12,
            },
          }}
        >
          <ThemeProvider theme={theme}>
            <DataGrid
              sx={{
                '&.MuiDataGrid-footerContainer': {
                  fontSize: 5,
                },

                '&.MuiDataGrid-root': {
                  fontSize: 12,
                },
                '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                  py: 1,
                },
                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                  py: '2px',
                },
                '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                  py: '3px',
                },
                '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                  {
                    outline: 'none !important',
                  },
              }}
              getRowHeight={() => 'auto'}
              getRowId={(row) => row.Id}
              columns={columns}
              rows={tankToWebRespDto}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...other}
              pageSize={50}
              rowsPerPageOptions={[50]}
              disableSelectionOnClick
              hideFooter
              experimentalFeatures={{ newEditingApi: true }}
            />
          </ThemeProvider>
        </Box>
      </div>
    </Stack>
  );
};
