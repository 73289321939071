import React, { useEffect, useState } from 'react';
import { Divider, IconButton, Stack, Typography } from '@mui/material';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { MyLinearProgressBar } from '../UI/MyLinearProgressBar';
import classes from './TanksDiv.module.css';
import { getDefaultAlertProps } from '../../utils/utils';
import { apiGetTankToWeb } from '../../services/apiGetTankToWeb';
import { IApiResult } from '../../interface/IApiResult';
import { IAlertProps } from '../../interface/IAlertProps';
import { paramStringRound } from '../../utils/common';

import tank from '../../images/tank.png';

import { API_STATUS_PROBLEM } from '../../constant/constants';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';

import { GetTankToWebRespDto } from '../../dto/GetTankToWebRespDto';
import { MyTextFieldScada } from '../UI/MyTextFieldScada';

export const TanksDivMobile = () => {
  const [tankToWebRespDto, setTankToWebRespDto] = useState<
    GetTankToWebRespDto[]
  >([]);

  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );

  const getTankInfo = async () => {
    const apiResult: IApiResult = await apiGetTankToWeb();
    if (apiResult.isSuccess) {
      setTankToWebRespDto(apiResult.data);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  useEffect(() => {
    getTankInfo();
  }, []);

  const tanks = tankToWebRespDto.map((item) => (
    <div>
      {item.IsWork === 0 && (
        <Typography
          style={{
            fontFamily: 'SF Compact Rounded',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '12px',
            color: 'red',
          }}
          component="text"
        >
          Сервер не отвечает. Проверьте работу сервера!
        </Typography>
      )}
      <Stack direction="row" justifyContent="space-between">
        <Typography
          align="center"
          marginBottom="1px"
          textAlign="left"
          marginTop="10px"
          fontWeight="600"
        >
          {`${item.TankType}-${item.TankCapacity}`}
        </Typography>
        <Stack direction="row" spacing={1}>
          {!(item.LevelStatus === '0' && item.TempStatus === '0') && (
            <IconButton sx={{ color: 'red' }}>
              <NotificationsActiveIcon />
            </IconButton>
          )}
        </Stack>
      </Stack>
      <Typography
        align="center"
        marginBottom="1px"
        textAlign="left"
        fontWeight="500"
      >
        {item.TankName}
      </Typography>
      <Stack
        direction="row"
        marginBottom="20px"
        spacing={0.05}
        alignItems="center"
        justifyItems="center"
        justifyContent="space-around"
      >
        <Stack direction="column" spacing={1}>
          <MyTextFieldScada
            statusIn={item.LevelStatus}
            value={`${paramStringRound(item.Level, 3)}`}
            label="H,  м"
          />
          <MyTextFieldScada
            statusIn={item.TempStatus}
            value={`${paramStringRound(item.TempAver, 2)}`}
            label="t, °C"
          />
          <MyTextFieldScada
            statusIn={item.DensStatus}
            value={`${paramStringRound(item.Dens, 2)}`}
            label="ρ, кг/м³"
          />
          <MyTextFieldScada
            statusIn={item.VolumeStatus}
            value={`${paramStringRound(item.Volume, 3)}`}
            label="V, м³"
          />
          <MyTextFieldScada
            statusIn={item.MassStatus}
            value={`${paramStringRound(item.Mass, 3)}`}
            label="M, т"
          />
        </Stack>
        <img
          src={tank}
          alt="Logo"
          width="45%"
          height="150px"
          className={classes.img}
        />
        <MyLinearProgressBar
          statusIn={item.LevelStatus}
          progress={Number(item.LevelProcent)}
          width="10px"
          height="120px"
        />
      </Stack>
      <Divider />
    </div>
  ));

  return (
    <Stack
      width="90%"
      spacing={2}
      alignItems="center"
      justifyItems="center"
      margin="20px"
    >
      {tanks}
    </Stack>
  );
};
