import React from 'react';
import {
  AppBar,
  Box,
  createTheme,
  IconButton,
  Stack,
  ThemeProvider,
  Toolbar,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import SchemaIcon from '@mui/icons-material/Schema';
import EventIcon from '@mui/icons-material/Event';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import {
  SIGN_IN_ROUTE,
  MAIN_ROUTE,
  EVENTS_ROUTE,
  TANKS_ROUTE,
} from '../../constant/routes';

export const MainNavigationBar = () => {
  const navigate = useNavigate();

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#605C5C',
      },
    },
  });

  const logout = () => {
    navigate(SIGN_IN_ROUTE);
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <ThemeProvider theme={darkTheme}>
        <AppBar position="static">
          <Toolbar>
            <Stack direction="row" spacing={2}>
              <IconButton onClick={() => navigate(MAIN_ROUTE)}>
                <HomeIcon />
              </IconButton>
              <IconButton onClick={() => navigate(TANKS_ROUTE)}>
                <SchemaIcon />
              </IconButton>
              <IconButton onClick={() => navigate(EVENTS_ROUTE)}>
                <EventIcon />
              </IconButton>
            </Stack>
            <div style={{ flex: 1 }}> </div>
            <Stack direction="row" alignItems="center" spacing={2}>
              <IconButton onClick={logout}>
                <LogoutIcon />
              </IconButton>
            </Stack>
          </Toolbar>
        </AppBar>
      </ThemeProvider>
    </Box>
  );
};
