/* eslint-disable no-unused-vars */
import React, { FunctionComponent } from 'react';
import { styled } from '@mui/material/styles';
import { InputAdornment, TextField } from '@mui/material';
import { MAIN_COLOR_STRING } from '../../constant/constants';

interface Props {
  label: string;
  disabled?: boolean;
  multiLine?: boolean;
  width?: string;
  size?: any;
  value?: string;
  type?: string;
  error?: boolean;
  helperText?: string;
  imageIcon?: any;
  onChange?: (e: any) => void;
}

const CssTextField = styled(TextField)({
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: '#000000',
  },
  '& .MuiFormLabel-root.Mui-disabled': {
    color: 'grey',
  },
  '& .MuiFormLabel-root': {
    color: MAIN_COLOR_STRING,
  },
  '& label.Mui-focused': {
    color: MAIN_COLOR_STRING,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: MAIN_COLOR_STRING,
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: MAIN_COLOR_STRING,
    },
    '&.Mui-focused fieldset': {
      borderColor: MAIN_COLOR_STRING,
    },
  },
});

export const MyTextFiled: FunctionComponent<Props> = ({
  label,
  multiLine,
  disabled,
  width,
  size,
  value,
  type,
  error,
  helperText,
  imageIcon,
  onChange,
}) => (
  <CssTextField
    label={label}
    value={value}
    variant="outlined"
    type={type}
    onChange={onChange}
    error={error}
    multiline={multiLine}
    disabled={disabled}
    style={{
      width,
      fontFamily: 'SF Compact Display',
    }}
    helperText={helperText}
    size={size}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">{imageIcon}</InputAdornment>
      ),
    }}
  />
);

MyTextFiled.defaultProps = {
  value: '',
  disabled: false,
  multiLine: false,
  width: '100%',
  size: 'large',
  type: 'text',
  error: false,
  helperText: '',
  imageIcon: undefined,
  onChange: undefined,
};
