import React, { useEffect, useState } from 'react';
import { Box, createTheme, ThemeProvider, Divider, Stack } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef, ruRU } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';

import BoltIcon from '@mui/icons-material/Bolt';
import LockIcon from '@mui/icons-material/Lock';

import ComputerIcon from '@mui/icons-material/Computer';
import PersonIcon from '@mui/icons-material/Person';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import GasMeterIcon from '@mui/icons-material/GasMeter';
import classes from './MainDiv.module.css';
import { getDefaultAlertProps } from '../../utils/utils';
import { MainDivComponent } from './MainDivComponent';
import { apiGetTankToWeb } from '../../services/apiGetTankToWeb';
import { IApiResult } from '../../interface/IApiResult';
import { IAlertProps } from '../../interface/IAlertProps';
import { getParamColorStyle, paramStringRound } from '../../utils/common';

import {
  ROOT_ADMIN_VALUE,
  ROOT_NAME,
  API_STATUS_PROBLEM,
} from '../../constant/constants';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import {
  SIGN_IN_ROUTE,
  MAIN_ROUTE,
  EVENTS_ROUTE,
  TANKS_ROUTE,
} from '../../constant/routes';

import { GetTankToWebRespDto } from '../../dto/GetTankToWebRespDto';

export const MainDiv = () => {
  const root = localStorage.getItem(ROOT_NAME);
  const navigate = useNavigate();

  const [tankToWebRespDto, setTankToWebRespDto] = useState<
    GetTankToWebRespDto[]
  >([]);

  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );

  const getTankInfo = async () => {
    const apiResult: IApiResult = await apiGetTankToWeb();
    console.log(apiResult);
    if (apiResult.isSuccess) {
      setTankToWebRespDto(apiResult.data);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  useEffect(() => {
    getTankInfo();
  }, []);

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    ruRU
  );

  const other = {
    autoHeight: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
  };

  const columns: GridColDef[] = [
    {
      field: 'DatetimeCicl',
      minWidth: 200,
      flex: 1,
      headerName: 'Дата и время',
      editable: false,
      disableColumnMenu: false,
    },

    {
      field: 'TankName',
      minWidth: 300,
      flex: 1,
      headerName: 'Резервуар',
      editable: false,
      disableColumnMenu: false,
    },

    {
      field: 'Level',
      minWidth: 150,
      flex: 1,
      headerName: 'Уровень, м',
      editable: false,
      disableColumnMenu: false,
      cellClassName: (row) => getParamColorStyle(row.row.LevelStatus),
      renderCell: (row) => paramStringRound(row.row.Level, 3),
    },

    {
      field: 'TempAver',
      minWidth: 150,
      flex: 1,
      headerName: 'Температура, °C',
      editable: false,
      disableColumnMenu: false,
      cellClassName: (row) => getParamColorStyle(row.row.TempStatus),
      renderCell: (row) => paramStringRound(row.row.TempAver, 2),
    },

    {
      field: 'Volume',
      minWidth: 150,
      flex: 1,
      headerName: 'Объем, м³',
      editable: false,
      disableColumnMenu: false,
      cellClassName: (row) => getParamColorStyle(row.row.VolumeStatus),
      renderCell: (row) => paramStringRound(row.row.Volume, 3),
    },

    {
      field: 'Dens',
      minWidth: 150,
      flex: 1,
      headerName: 'Плотность, кг/м³',
      editable: false,
      disableColumnMenu: false,
      cellClassName: (row) => getParamColorStyle(row.row.DensStatus),
      renderCell: (row) => paramStringRound(row.row.Dens, 2),
    },

    {
      field: 'Mass',
      minWidth: 150,
      flex: 1,
      headerName: 'Масса, т',
      editable: false,
      disableColumnMenu: false,
      cellClassName: (row) => getParamColorStyle(row.row.MassStatus),
      renderCell: (row) => paramStringRound(row.row.Mass, 3),
    },
  ];

  return (
    <Stack>
      <div>
        {/* <Box
          sx={{
            width: '100%',
            '& .stage-black': {
              color: '#000000',
              fontWeight: '300',
            },
            '& .stage-yellow': {
              color: '#ffff00',
              fontWeight: '300',
            },
            '& .stage-red': {
              color: '#ff0000',
              fontWeight: '300',
            },

            '& .stage-magenta': {
              color: '#ff00ff',
              fontWeight: '300',
            },

            '& .stage-blue': {
              color: '#0000ff',
              fontWeight: '300',
            },
          }}
        >
          <ThemeProvider theme={theme}>
            <DataGrid
              sx={{
                '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                  py: 1,
                },
                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                  py: '5px',
                },
                '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                  py: '10px',
                },
                '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                  {
                    outline: 'none !important',
                  },
              }}
              getRowHeight={() => 'auto'}
              getRowId={(row) => row.Id}
              columns={columns}
              rows={tankToWebRespDto}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...other}
              pageSize={20}
              rowsPerPageOptions={[20]}
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
            />
          </ThemeProvider>
        </Box> */}
      </div>
    </Stack>
  );
};
