export const getParamColorStyle = (
  status: string,
  isColor?: boolean
): string => {
  let colorText = '#000000';
  switch (status) {
    case '0':
      colorText = 'stage-green';
      break;
    case '1':
      colorText = 'stage-orange';
      break;

    case '2':
      colorText = 'stage-orange';
      break;

    case '3':
      colorText = 'stage-red';
      break;

    case '4':
      colorText = 'stage-red';
      break;

    case '5':
      colorText = 'stage-magenta';
      break;

    case '6':
      colorText = 'stage-magenta';
      break;
    default:
      colorText = 'stage-blue';
      break;
  }
  if (isColor) {
    colorText = colorText.replaceAll('stage-', '');
  }
  return colorText;
};

export const getParamColor = (status: string): string => {
  switch (status) {
    case '0':
      return 'green';

    case '1':
      return 'orange';

    case '2':
      return 'orange';

    case '3':
      return 'red';

    case '4':
      return 'red';

    case '5':
      return 'magenta';

    case '6':
      return 'magenta';

    default:
      return 'blue';
  }
};

export const getEventColor = (status: string): string => {
  switch (status) {
    case '1':
      return 'orange';

    case '2':
      return 'red';
    default:
      return 'red';
  }
};

export const paramStringRound = (param: string, accuracy: number): string => {
  if (param === 'NaN') return 'NaN';

  return (
    Math.floor(Number(param) * 10 ** accuracy) /
    10 ** accuracy
  ).toString();
};

export const getCurrentDate = (timestamp: number) => {
  const newDate = new Date(timestamp);
  const date = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();

  return `${year}-${month < 10 ? `0${month}` : `${month}`}-${
    date < 10 ? `0${date}` : `${date}`
  }`;
};
