import React from 'react';
import { TanksDiv } from '../component/tanks/TanksDiv';
import { TanksDivMobile } from '../component/tanks/TanksDivMobile';
import { MainNavigationBar } from '../component/navigation-bar/MainNavigationBar';
import { TanksPageLayout } from '../layout/TanksPageLayout';
import { isMobileView } from '../utils/utils';

export const TanksPage = () => (
  <TanksPageLayout
    navigationBar={<MainNavigationBar />}
    elementCentr={isMobileView() ? <TanksDivMobile /> : <TanksDiv />}
  />
);
