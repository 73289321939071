import React from 'react';
import { EventsDiv } from '../component/events/EventsDiv';
import { EventsDivMobile } from '../component/events/EventsDivMobile';
import { MainNavigationBar } from '../component/navigation-bar/MainNavigationBar';
import { EventsPageLayout } from '../layout/EventsPageLayout';
import { isMobileView } from '../utils/utils';

export const EventsPage = () => (
  <EventsPageLayout
    navigationBar={<MainNavigationBar />}
    elementCentr={isMobileView() ? <EventsDivMobile /> : <EventsDiv />}
  />
);
