import React, { useEffect, useState } from 'react';
import {
  Box,
  createTheme,
  ThemeProvider,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { DataGrid, GridCellParams, GridColDef, ruRU } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';

import BoltIcon from '@mui/icons-material/Bolt';
import LockIcon from '@mui/icons-material/Lock';

import ComputerIcon from '@mui/icons-material/Computer';
import PersonIcon from '@mui/icons-material/Person';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import GasMeterIcon from '@mui/icons-material/GasMeter';
import { MyTextFieldDynamic } from '../UI/MyTextFieldDynamic';
import classes from './TanksDiv.module.css';
import { getDefaultAlertProps } from '../../utils/utils';
import { apiGetTankToWeb } from '../../services/apiGetTankToWeb';
import { IApiResult } from '../../interface/IApiResult';
import { IAlertProps } from '../../interface/IAlertProps';
import { getParamColorStyle, paramStringRound } from '../../utils/common';

import tank from '../../images/tank.png';

import {
  ROOT_ADMIN_VALUE,
  ROOT_NAME,
  API_STATUS_PROBLEM,
} from '../../constant/constants';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import {
  SIGN_IN_ROUTE,
  MAIN_ROUTE,
  EVENTS_ROUTE,
  TANKS_ROUTE,
} from '../../constant/routes';

import { GetTankToWebRespDto } from '../../dto/GetTankToWebRespDto';

export const TanksDiv = () => {
  const root = localStorage.getItem(ROOT_NAME);
  const navigate = useNavigate();

  const [tankToWebRespDto, setTankToWebRespDto] = useState<
    GetTankToWebRespDto[]
  >([]);

  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );

  const getTankInfo = async () => {
    const apiResult: IApiResult = await apiGetTankToWeb();
    console.log(apiResult);
    if (apiResult.isSuccess) {
      setTankToWebRespDto(apiResult.data);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  useEffect(() => {
    getTankInfo();
  }, []);

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    ruRU
  );

  const other = {
    autoHeight: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
  };

  const tanks = tankToWebRespDto.map((item) => (
    <div>
      {/* <Typography align="center" font-size="100px">
        {item.TankName}
      </Typography>
      <Stack direction="row">
        <Stack direction="column" spacing={2} margin="20px">
          <MyTextFieldDynamic
            statusIn={item.LevelStatus}
            value={paramStringRound(item.Level, 3)}
            size="small"
            label="Уровень, м"
          />
          <MyTextFieldDynamic
            statusIn={item.TempStatus}
            value={paramStringRound(item.TempAver, 2)}
            size="small"
            label="Температура, °C"
          />
          <MyTextFieldDynamic
            statusIn={item.VolumeStatus}
            value={paramStringRound(item.Volume, 3)}
            size="small"
            label="Объем, м³"
          />
          <MyTextFieldDynamic
            statusIn={item.DensStatus}
            value={paramStringRound(item.Dens, 2)}
            size="small"
            label="Плотность, кг/м³"
          />
          <MyTextFieldDynamic
            statusIn={item.MassStatus}
            value={paramStringRound(item.Mass, 3)}
            size="small"
            label="Масса, т"
          />
        </Stack>
        <img src={tank} alt="Logo" />
      </Stack> */}
    </div>
  ));

  return (
    <Stack width="1000px" alignItems="center" justifyContent="space-around">
      {tanks}
    </Stack>
  );
};
