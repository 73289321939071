import React, { useEffect, useState } from 'react';
import {
  Box,
  createTheme,
  ThemeProvider,
  Stack,
  IconButton,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { getDefaultAlertProps } from '../../utils/utils';
import { apiGetEvent } from '../../services/apiGetEvent';
import { IApiResult } from '../../interface/IApiResult';
import { IAlertProps } from '../../interface/IAlertProps';
import { getCurrentDate, getEventColor } from '../../utils/common';
import { MyDateTextField } from '../UI/MyDateTextField';
import { API_STATUS_PROBLEM } from '../../constant/constants';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';

import { GetEventDto } from '../../dto/GetEventDto';
import { GetEventRespDto } from '../../dto/GetEventRespDto';

export const EventsDivMobile = () => {
  const [eventDto, setEventDto] = useState<GetEventDto>({
    from: getCurrentDate(Date.now() - 2629743000),
    to: getCurrentDate(Date.now() + 86400000),
  });

  const [eventRespDto, setEventRespDto] = useState<GetEventRespDto[]>([]);

  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );

  const getEventInfo = async () => {
    const apiResult: IApiResult = await apiGetEvent(eventDto);
    if (apiResult.isSuccess) {
      setEventRespDto(apiResult.data);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  useEffect(() => {
    getEventInfo();
  }, []);

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    ruRU
  );

  const other = {
    autoHeight: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
  };

  const columns: GridColDef[] = [
    {
      field: 'Event',
      flex: 1,
      headerName: 'Событие',
      editable: false,
      disableColumnMenu: false,
      renderCell: (row) => (
        <Stack width="100%" spacing={1}>
          <Stack direction="row" spacing={1}>
            <Typography
              style={{
                fontFamily: 'SF Compact Rounded',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '12px',
                color: '#000000',
              }}
              component="text"
            >
              {row.row.Datetime}
            </Typography>
            <Typography
              style={{
                fontFamily: 'SF Compact Rounded',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '12px',
                color: '#000000',
              }}
              component="text"
            >
              {row.row.TankId}
            </Typography>
          </Stack>
          <Typography
            style={{
              fontFamily: 'SF Compact Rounded',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '12px',
              color: getEventColor(row.row.Status),
            }}
            component="text"
          >
            {row.row.Event}
          </Typography>
        </Stack>
      ),
    },
  ];

  return (
    <Stack padding="5px" direction="column">
      <Stack direction="row" alignItems="center" padding="10px" spacing={1}>
        <MyDateTextField
          value={eventDto.from}
          size="small"
          onChange={(e) => setEventDto({ ...eventDto, from: e.target.value })}
          label="Начало"
        />

        <MyDateTextField
          value={eventDto.to}
          size="small"
          onChange={(e) => setEventDto({ ...eventDto, to: e.target.value })}
          label="Конец"
        />
        <IconButton onClick={getEventInfo}>
          <FindInPageIcon />
        </IconButton>
      </Stack>
      <div>
        <Box
          sx={{
            width: '100%',
            '& .stage-black': {
              color: '#000000',
              fontWeight: 100,
              fontSize: 12,
            },
            '& .stage-orange': {
              color: 'orange',
              fontWeight: 100,
              fontSize: 12,
            },
            '& .stage-red': {
              color: '#ff0000',
              fontWeight: 100,
              fontSize: 12,
            },

            '& .stage-magenta': {
              color: '#ff00ff',
              fontWeight: 100,
              fontSize: 12,
            },

            '& .stage-blue': {
              color: '#0000ff',
              fontWeight: 100,
              fontSize: 12,
            },
          }}
        >
          <ThemeProvider theme={theme}>
            <DataGrid
              sx={{
                '&.MuiDataGrid-footerContainer': {
                  fontSize: 12,
                },

                '&.MuiDataGrid-root': {
                  fontSize: 12,
                },
                '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                  py: 1,
                },
                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                  py: '5px',
                },
                '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                  py: '10px',
                },
                '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                  {
                    outline: 'none !important',
                  },
              }}
              getRowHeight={() => 'auto'}
              getRowId={(row) => row.Id}
              columns={columns}
              rows={eventRespDto}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...other}
              pageSize={20}
              rowsPerPageOptions={[20]}
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
            />
          </ThemeProvider>
        </Box>
      </div>
    </Stack>
  );
};
