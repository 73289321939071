import React, { FunctionComponent } from 'react';
import classes from './TanksPageLayout.module.css';

interface Props {
  navigationBar?: React.ReactNode;
  elementCentr?: React.ReactNode;
}

export const TanksPageLayout: FunctionComponent<Props> = ({
  navigationBar,
  elementCentr,
}) => (
  <div style={{ userSelect: 'none' }} className={classes.myDiv}>
    <div className={classes.container}>
      <div className={classes.elementBar}>{navigationBar}</div>
      <div className={classes.elementCentr}>{elementCentr}</div>
    </div>
  </div>
);

TanksPageLayout.defaultProps = {
  navigationBar: undefined,
  elementCentr: undefined,
};
