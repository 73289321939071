/* eslint-disable no-unused-vars */
import React, { FunctionComponent } from 'react';
import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';
import { MAIN_COLOR_STRING } from '../../constant/constants';
import { changeFormatDateWithDiff } from '../../utils/utils';

interface Props {
  label: string;
  width?: string;
  disabled?: boolean;
  size?: any;
  value?: string;
  error?: boolean;
  helperText?: string;
  onChange?: (e: any) => void;
}

const CssTextField = styled(TextField)({
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: '#000000',
  },
  '& label.Mui-focused': {
    color: MAIN_COLOR_STRING,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: MAIN_COLOR_STRING,
  },
  '& .MuiFormLabel-root.Mui-disabled': {
    color: 'grey',
  },
  '& .MuiFormLabel-root': {
    color: MAIN_COLOR_STRING,
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: MAIN_COLOR_STRING,
    },
    '&.Mui-focused fieldset': {
      borderColor: MAIN_COLOR_STRING,
    },
  },
});

export const MyDateTextField: FunctionComponent<Props> = ({
  label,
  width,
  disabled,
  size,
  value,
  error,
  helperText,
  onChange,
}) => (
  <CssTextField
    id="date"
    label={label}
    value={changeFormatDateWithDiff(value)}
    variant="outlined"
    type="date"
    onChange={onChange}
    error={error}
    disabled={disabled}
    style={{
      width,
      fontFamily: 'SF Compact Display',
    }}
    helperText={helperText}
    size={size}
    InputLabelProps={{
      shrink: true,
      style: { fontSize: 12 },
    }}
    inputProps={{ style: { fontSize: 12 } }} // font size of input text
  />
);

MyDateTextField.defaultProps = {
  value: '',
  width: '100%',
  size: 'small',
  disabled: false,
  error: false,
  helperText: '',
  onChange: undefined,
};
