/* eslint-disable react/jsx-props-no-spreading */
import React, { FunctionComponent } from 'react';
import { styled } from '@mui/material/styles';
import LinearProgress, {
  LinearProgressProps,
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { getParamColor } from '../../utils/common';

interface Props {
  // eslint-disable-next-line react/require-default-props
  statusIn: string;
  width?: string;
  height?: string;
  // eslint-disable-next-line react/require-default-props
  progress: number;
}

export const MyLinearProgressBar: FunctionComponent<Props> = ({
  statusIn,
  width,
  height,
  progress,
}) => {
  const MyLinearProgressBarTemp = styled(LinearProgress)(() => ({
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: '#F5F6FA',
    },
    [`&.${linearProgressClasses.colorSecondary}`]: {
      backgroundColor: getParamColor(statusIn),
    },
  }));

  return (
    <MyLinearProgressBarTemp
      style={{
        width,
        height,
      }}
      variant="determinate"
      color="secondary"
      value={progress}
      sx={{
        [`& .${linearProgressClasses.bar}`]: {
          transform: `translateY(${-progress}%)!important`,
        },
      }}
    />
  );
};

MyLinearProgressBar.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  statusIn: '0',
  width: '160px',
  height: '160px',
  // eslint-disable-next-line react/default-props-match-prop-types
  progress: 0,
};
