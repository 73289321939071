import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';
import {
  ACCESS_TOKEN_NAME,
  API_STATUS_PROBLEM,
  API_STATUS_INVALID_LOGIN_PASSWORD,
  API_SET_LOGIN_PASSWORD,
} from '../../constant/constants';
import { MAIN_ROUTE } from '../../constant/routes';
import { IApiResult } from '../../interface/IApiResult';
import { apiSignIn } from '../../services/apiSignIn';
import { IAlertProps } from '../../interface/IAlertProps';
import { getDefaultAlertProps } from '../../utils/utils';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { SignInDto } from '../../dto/SignInDto';

export const SignInDiv = () => {
  localStorage.clear();
  const navigate = useNavigate();
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [signInDto, setSignInDto] = useState<SignInDto>({
    login: '',
    password: '',
  });

  const signIn = async () => {
    const apiResult: IApiResult = await apiSignIn(signInDto);
    if (
      apiResult.isSuccess &&
      (apiResult.data.root === 2 || apiResult.data.root === 4)
    ) {
      localStorage.setItem(ACCESS_TOKEN_NAME, 'tank-server');
      navigate(MAIN_ROUTE);
    } else if (apiResult.status === 404 || apiResult.data.root === -1) {
      setAlertProps({
        message: API_STATUS_INVALID_LOGIN_PASSWORD,
        severity: AlertSeverityEnum.error,
      });
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const handleSignIn = async () => {
    if (signInDto.login === '' || signInDto.password === '') {
      setAlertProps({
        message: API_SET_LOGIN_PASSWORD,
        severity: AlertSeverityEnum.info,
      });
      return;
    }
    await signIn();
  };

  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      {/* <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Card style={{ padding: '40px', width: '15%' }}>
        <Stack width="100%" spacing={2}>
          <Typography
            style={{
              fontFamily: 'SF Compact Rounded',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: MAIN_TITLE_FONTSIZE_STRING,
              letterSpacing: 1,
              color: '#000000',
            }}
            component="text"
          >
            Вход в личный кабинет
          </Typography>
          <Divider />
          <MyTextFiled
            value={signInDto.login}
            size="small"
            onChange={(e) =>
              setSignInDto({ ...signInDto, login: e.target.value })
            }
            label="Логин"
          />
          <MyPasswordTextField
            value={signInDto.password}
            size="small"
            onChange={(e) =>
              setSignInDto({ ...signInDto, password: e.target.value })
            }
            label="Пароль"
          />
          <MyButton
            text="Войти"
            background={MAIN_COLOR_STRING}
            onClick={handleSignIn}
          />
        </Stack>
      </Card> */}
    </Stack>
  );
};
