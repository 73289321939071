import React, { useEffect, useState } from 'react';
import { Box, createTheme, ThemeProvider, Divider, Stack } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef, ruRU } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';

import BoltIcon from '@mui/icons-material/Bolt';
import LockIcon from '@mui/icons-material/Lock';

import ComputerIcon from '@mui/icons-material/Computer';
import PersonIcon from '@mui/icons-material/Person';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import GasMeterIcon from '@mui/icons-material/GasMeter';
import classes from './EventsDiv.module.css';
import { getDefaultAlertProps } from '../../utils/utils';
import { apiGetEvent } from '../../services/apiGetEvent';
import { IApiResult } from '../../interface/IApiResult';
import { IAlertProps } from '../../interface/IAlertProps';
import { getParamColorStyle, getCurrentDate } from '../../utils/common';
import { MyDateTextField } from '../UI/MyDateTextField';
import { MyButton } from '../UI/MyButton';
import {
  ROOT_ADMIN_VALUE,
  ROOT_NAME,
  API_STATUS_PROBLEM,
} from '../../constant/constants';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import {
  SIGN_IN_ROUTE,
  MAIN_ROUTE,
  EVENTS_ROUTE,
  TANKS_ROUTE,
} from '../../constant/routes';

import { GetEventDto } from '../../dto/GetEventDto';
import { GetEventRespDto } from '../../dto/GetEventRespDto';

export const EventsDiv = () => {
  const root = localStorage.getItem(ROOT_NAME);
  const navigate = useNavigate();

  const [eventDto, setEventDto] = useState<GetEventDto>({
    from: getCurrentDate(Date.now() - 2629743000),
    to: getCurrentDate(Date.now() + 86400000),
  });

  console.log(eventDto);

  const [eventRespDto, setEventRespDto] = useState<GetEventRespDto[]>([]);

  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );

  const getEventInfo = async () => {
    const apiResult: IApiResult = await apiGetEvent(eventDto);
    console.log(apiResult);
    if (apiResult.isSuccess) {
      setEventRespDto(apiResult.data);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  useEffect(() => {
    getEventInfo();
  }, []);

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    ruRU
  );

  const other = {
    autoHeight: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
  };

  const columns: GridColDef[] = [
    {
      field: 'Datetime',
      minWidth: 200,
      flex: 1,
      headerName: 'Дата и время',
      editable: false,
      disableColumnMenu: false,
      cellClassName: (row) => getParamColorStyle(row.row.Status),
    },

    {
      field: 'TankId',
      minWidth: 300,
      flex: 1,
      headerName: 'Резервуар',
      editable: false,
      disableColumnMenu: false,
      cellClassName: (row) => getParamColorStyle(row.row.Status),
    },

    {
      field: 'Event',
      minWidth: 500,
      flex: 1,
      headerName: 'Событие',
      editable: false,
      disableColumnMenu: false,
      cellClassName: (row) => getParamColorStyle(row.row.Status),
    },
  ];

  return (
    <Stack direction="column">
      {/* <Stack direction="row" margin="20px">
        <MyDateTextField
          value={eventDto.from}
          size="small"
          onChange={(e) => setEventDto({ ...eventDto, from: e.target.value })}
          label="Начало"
        />

        <MyDateTextField
          value={eventDto.to}
          size="small"
          onChange={(e) => setEventDto({ ...eventDto, to: e.target.value })}
          label="Конец"
        />

        <MyButton
          text="Сформировать"
          background="#000000"
          onClick={getEventInfo}
        />
      </Stack> */}
      <div>
        {/* <Box
          sx={{
            width: '100%',
            '& .stage-black': {
              color: '#000000',
              background: '#FFFFFF',
              fontWeight: '300',
            },
            '& .stage-yellow': {
              color: '#000000',
              background: '#ffff00',
              fontWeight: '300',
            },
            '& .stage-red': {
              color: '#000000',
              background: '#ff0000',
              fontWeight: '300',
            },

            '& .stage-magenta': {
              color: '#000000',
              background: '#ff00ff',
              fontWeight: '300',
            },

            '& .stage-blue': {
              color: '#FFFFFF',
              background: '#0000ff',
              fontWeight: '300',
            },
          }}
        >
          <ThemeProvider theme={theme}>
            <DataGrid
              sx={{
                '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                  py: 1,
                },
                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                  py: '5px',
                },
                '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                  py: '10px',
                },
                '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                  {
                    outline: 'none !important',
                  },
              }}
              getRowHeight={() => 'auto'}
              getRowId={(row) => row.Id}
              columns={columns}
              rows={eventRespDto}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...other}
              pageSize={20}
              rowsPerPageOptions={[20]}
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
            />
          </ThemeProvider>
        </Box> */}
      </div>
    </Stack>
  );
};
