/* eslint-disable no-unused-vars */
import React, { FunctionComponent } from 'react';
import { Stack, Typography } from '@mui/material';
import { getParamColor } from '../../utils/common';

interface Props {
  // eslint-disable-next-line react/require-default-props
  statusIn: string;
  label: string;
  value: string;
}

export const MyTextFieldScada: FunctionComponent<Props> = ({
  statusIn,
  label,
  value,
}) => (
  <Stack direction="row" width="120px" justifyContent="space-between">
    <Typography
      style={{
        fontFamily: 'SF Compact Rounded',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '12px',
        color: getParamColor(statusIn),
      }}
      component="text"
    >
      {label}
    </Typography>
    <Typography
      style={{
        fontFamily: 'SF Compact Rounded',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '12px',
        color: getParamColor(statusIn),
      }}
      component="text"
    >
      {value}
    </Typography>
  </Stack>
);
