import { JSON_CONTENT_TYPE, ACCESS_TOKEN_NAME } from '../constant/constants';
import { GetEventDto } from '../dto/GetEventDto';
import { IApiParams } from '../interface/IApiParams';
import { getApi } from './getApi';

export const apiGetEvent = async (dto: GetEventDto) => {
  const params: IApiParams = {
    url: `/getEvent.php?from=${dto.from}&to=${dto.to}`,
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      'Access-Token': localStorage.getItem(ACCESS_TOKEN_NAME),
    },
  };
  return getApi(params, 'GET');
};
