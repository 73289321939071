import React from 'react';
import { MainDiv } from '../component/main/MainDiv';
import { MainDivMobile } from '../component/main/MainDivMobile';
import { MainNavigationBar } from '../component/navigation-bar/MainNavigationBar';
import { MainPageLayout } from '../layout/MainPageLayout';
import { isMobileView } from '../utils/utils';

export const MainPage = () => (
  <MainPageLayout
    navigationBar={<MainNavigationBar />}
    elementCentr={isMobileView() ? <MainDivMobile /> : <MainDiv />}
  />
);
