import React from 'react';
import {
  AppBar,
  Box,
  createTheme,
  ThemeProvider,
  Toolbar,
  Typography,
} from '@mui/material';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#605C5C',
    },
  },
});

export const SignNavigationBar = () => (
  <Box sx={{ flexGrow: 1 }}>
    <ThemeProvider theme={darkTheme}>
      <AppBar position="static">
        <Toolbar>
          <Typography
            style={{
              fontFamily: 'SF Compact Rounded',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '18px',
              color: '#FFFFFF',
            }}
            component="text"
          >
            TANK Web
          </Typography>
          <div style={{ flex: 1 }}> </div>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  </Box>
);
