import React, { FunctionComponent, useState } from 'react';
import { styled } from '@mui/material/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { InputAdornment, TextField } from '@mui/material';
import { MAIN_COLOR_STRING } from '../../constant/constants';

interface Props {
  label: string;
  width?: string;
  value?: any;
  error?: boolean;
  helperText?: string;
  imageIcon?: any;
  size?: any;
  isVisible?: boolean;
  // eslint-disable-next-line no-unused-vars
  onChange?: (e: any) => void;
}

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: MAIN_COLOR_STRING,
  },
  '& .MuiFormLabel-root.Mui-disabled': {
    color: 'grey',
  },
  '& .MuiFormLabel-root': {
    color: MAIN_COLOR_STRING,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: MAIN_COLOR_STRING,
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: MAIN_COLOR_STRING,
    },
    '&.Mui-focused fieldset': {
      borderColor: MAIN_COLOR_STRING,
    },
  },
});

export const MyPasswordTextField: FunctionComponent<Props> = ({
  label,
  width,
  value,
  error,
  helperText,
  imageIcon,
  size,
  isVisible,
  onChange,
}) => {
  const [textFieldType, setTextFieldType] = useState('password');
  const handleShowPassword = () => {
    if (textFieldType === 'password') {
      setTextFieldType('text');
    } else {
      setTextFieldType('password');
    }
  };

  return (
    <CssTextField
      label={label}
      value={value}
      variant="outlined"
      size={size}
      type={textFieldType}
      onChange={onChange}
      error={error}
      style={{
        fontFamily: 'SF Compact Display',
        width,
        visibility: isVisible ? 'visible' : 'collapse',
      }}
      helperText={helperText}
      InputProps={{
        autoComplete: 'password',
        startAdornment: (
          <InputAdornment position="start">{imageIcon}</InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <VisibilityIcon
              onClick={handleShowPassword}
              style={{ cursor: 'pointer' }}
            />
          </InputAdornment>
        ),
      }}
    />
  );
};

MyPasswordTextField.defaultProps = {
  isVisible: true,
  width: '100%',
  value: '',
  size: 'large',
  error: false,
  helperText: '',
  imageIcon: undefined,
  onChange: undefined,
};
