import { JSON_CONTENT_TYPE, ACCESS_TOKEN_NAME } from '../constant/constants';
import { IApiParams } from '../interface/IApiParams';
import { getApi } from './getApi';

export const apiGetTankToWeb = async () => {
  const params: IApiParams = {
    url: '/getTankToWeb.php',
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      'Access-Token': localStorage.getItem(ACCESS_TOKEN_NAME),
    },
  };
  return getApi(params, 'GET');
};
