import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { SignInPage } from '../../page/SignInPage';
import { MainPage } from '../../page/MainPage';
import { TanksPage } from '../../page/TanksPage';
import { EventsPage } from '../../page/EventsPage';
import {
  SIGN_IN_ROUTE,
  MAIN_ROUTE,
  EVENTS_ROUTE,
  TANKS_ROUTE,
} from '../../constant/routes';

export const App = () => (
  <div>
    <Routes>
      <Route path={SIGN_IN_ROUTE} element={<SignInPage />} />
      <Route path={MAIN_ROUTE} element={<MainPage />} />
      <Route path={TANKS_ROUTE} element={<TanksPage />} />
      <Route path={EVENTS_ROUTE} element={<EventsPage />} />
    </Routes>
  </div>
);
