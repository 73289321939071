import React from 'react';
import { SignInDiv } from '../component/sign-in/SignInDiv';
import { SignInDivMobile } from '../component/sign-in/SignInDivMobile';
import { SignInPageLayout } from '../layout/SignInPageLayout';
import { SignNavigationBar } from '../component/navigation-bar/SignNavigationBar';
import { isMobileView } from '../utils/utils';

export const SignInPage = () => (
  <SignInPageLayout
    navigationBar={<SignNavigationBar />}
    centerDesktop={isMobileView() ? <SignInDivMobile /> : <SignInDiv />}
  />
);
